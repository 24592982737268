import React from 'react';
import {
  Box,
  Flex,
  Link,
  Heading,
  HStack,
  Icon,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaBitcoin, FaBars } from 'react-icons/fa';
// const WalletConnect = WalletConnect;

const Navigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const MenuItems = ({ isMobile, onClose }) => (
    <>
      <Link 
        as={RouterLink} 
        to="/about" 
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        onClick={onClose}
      >
        Quienes Somos
      </Link>
      <Link 
        as={RouterLink} 
        to="/physical-payments"
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        onClick={onClose}
      >
        Pagos Físicos
      </Link>
      <Link 
        as={RouterLink} 
        to="/virtual-payments"
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        onClick={onClose}
      >
        Pagos Virtuales
      </Link>
      <Link 
        as={RouterLink} 
        to="/wallet"
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        onClick={onClose}
      >
        Wallet
      </Link>
      <Link 
        href="https://www.solucionescrypto.com/login"
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClose}
      >
        Transacciones
      </Link>
      <Link 
        as={RouterLink} 
        to="/contact"
        fontSize="lg"
        fontWeight="medium"
        color="white"
        px={4}
        py={2}
        borderRadius="md"
        _hover={{ 
          bg: 'blue.500',
          color: 'yellow.200',
          transform: 'translateY(-2px)'
        }}
        transition="all 0.2s"
        onClick={onClose}
      >
        Contáctanos
      </Link>
    </>
  );

  return (
    <Box 
      bg="blue.600" 
      px={8} 
      py={4} 
      position="sticky" 
      top={0} 
      zIndex={10}
      boxShadow="md"
    >
      <Flex 
        h={16} 
        alignItems="center" 
        justifyContent="space-between"
        maxW="container.xl"
        mx="auto"
      >
        <HStack spacing={8} alignItems="center" as={RouterLink} to="/" _hover={{ textDecoration: 'none' }}>
          <Icon 
            as={FaBitcoin} 
            w={10} 
            h={10} 
            color="yellow.300"
            transition="transform 0.2s"
            _hover={{ transform: 'rotate(20deg)' }}
          />
          <Heading 
            size="lg" 
            color="white"
            fontWeight="bold"
          >
            Datafono Crypto
          </Heading>
        </HStack>

        <HStack 
          spacing={8} 
          display={{ base: 'none', md: 'flex' }}
        >
          <MenuItems isMobile={false} onClose={onClose} />
        </HStack>

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          icon={<FaBars />}
          variant="outline"
          color="white"
          size="lg"
          _hover={{ 
            bg: 'blue.500',
            transform: 'scale(1.1)'
          }}
          transition="all 0.2s"
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="blue.600">
          <DrawerCloseButton color="white" />
          <DrawerHeader borderBottomWidth="1px" color="white">Menú</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch" mt={4}>
              <MenuItems isMobile={true} onClose={onClose} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navigation; 