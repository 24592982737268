import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import './index.css';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import emailjs from '@emailjs/browser';

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

if (!process.env.REACT_APP_EMAILJS_PUBLIC_KEY) {
  console.error('¡Advertencia! No se encontró la clave pública de EmailJS');
}
if (!process.env.REACT_APP_EMAILJS_SERVICE_ID) {
  console.error('¡Advertencia! No se encontró el ID de servicio de EmailJS');
}
if (!process.env.REACT_APP_EMAILJS_TEMPLATE_ID) {
  console.error('¡Advertencia! No se encontró el ID de plantilla de EmailJS');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); 